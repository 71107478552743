<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí trình độ khóa học theo nhóm sản phẩm {{ category_title }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogCourse"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm cấu hình trình độ</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp lại</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Course</th>
                    <th scope="col">Level</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(course, i) in product_category_courses">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ course.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          v-if="course.course"
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ course.course.name.vi }}
                        </p>
                      </td>
                      <td>
                        <!--                      <p class="text-dark-75 font-weight-bolder d-block font-size-lg">{{course.entry_level}} -> {{course.end_of_course_level}}</p>-->
                        <p
                          v-if="course.level"
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ course.level.name }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editCaCourse(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteCaCourse(course.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogCourse" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Thêm cấu hình trình độ</span
              >
              <span class="headline" v-else>Sửa cấu hình trình độ</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogCourse = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <!--                  <v-row class="mt-8">-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Entry level:</label></v-col>-->
                  <!--                    <v-col cols="8" class="ml-4 p-0">-->
                  <!--                      <v-text-field type="number" dense v-model="entry_level_input" filled></v-text-field>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <!--                  <v-row class="mt-8">-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6"> End of course level:</label></v-col>-->
                  <!--                    <v-col cols="8" class="ml-4 p-0">-->
                  <!--                      <v-text-field type="number" dense v-model="end_of_course_level_input" filled></v-text-field>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Level:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        dense
                        filled
                        chips
                        v-model="level_input"
                        :items="levels"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Course:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        dense
                        filled
                        chips
                        v-model="course_input"
                        :items="courses"
                        item-text="name.vi"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditCourse(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditCourse(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogCourse = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp target</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(course, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ course.name }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortCaCourse"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import draggable from "vuedraggable";
import Swal from "sweetalert2";

export default {
  name: "CategoryCourse",
  components: {
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      category_title: "",
      product_category_courses: [],
      product_category_course_id: null,
      courses: [],
      type_form: null,

      dialogCourse: false,
      data_draggable: [],
      dialogSort: false,
      name_input: null,
      entry_level_input: null,
      end_of_course_level_input: null,
      course_input: null,
      category_course_id: null,
      levels: [],
      level_input: null,
    };
  },
  computed: {
    category_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllCategoryProductCourse();
    this.getAllCourse();
    this.getAllLevel();
  },
  methods: {
    async getAllCategoryProductCourse() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/product-category/" + this.category_id + "/course"
        );
        if (res.status === 200) {
          vm.category_title = res.data.category;
          vm.product_category_courses = res.data.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getAllCourse() {
      let vm = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status === 200) {
          vm.courses = response.data;
        }
      });
    },
    async getAllLevel() {
      let vm = this;
      await ApiService.get(
        "prep-app/product-category/level?category_id=" + this.category_id
      ).then(function (response) {
        if (response.status === 200) {
          vm.levels = response.data;
        }
      });
    },
    openDiaLogCourse() {
      this.name_input = null;
      this.entry_level_input = null;
      this.end_of_course_level_input = null;
      this.course_input = null;
      this.level_input = null;
      this.type_form = "add";
      this.dialogCourse = true;
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(
        JSON.stringify(this.product_category_courses)
      );
      this.dialogSort = true;
    },
    editCaCourse(index) {
      let data = JSON.parse(
        JSON.stringify(this.product_category_courses[index])
      );
      this.category_course_id = data.id;
      this.name_input = data.name;
      // this.entry_level_input = data.entry_level;
      // this.end_of_course_level_input = data.end_of_course_level;
      this.course_input = data.course_id;
      this.level_input = data.level == null ? null : data.level.id;
      this.type_form = "update";
      this.dialogCourse = true;
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập Name!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      // if (this.entry_level_input === "" || this.entry_level_input == null) {
      //   this.$toasted.error('Hãy nhập start level và đúng hợp lệ !!', {theme: "toasted-primary", position: "top-right", duration: 4000});
      //   flat = false;
      // } else {
      //   if (this.entry_level_input <= 0) {
      //     this.$toasted.error('Giá trị start level phải lớn hơn 0!!', {theme: "toasted-primary", position: "top-right", duration: 4000});
      //     flat = false;
      //   }
      // }
      // if (this.end_of_course_level_input === "" || this.end_of_course_level_input == null) {
      //   this.$toasted.error('Hãy nhập end level và đúng hợp lệ !!', {theme: "toasted-primary", position: "top-right", duration: 4000});
      //   flat = false;
      // } else {
      //   if (this.end_of_course_level_input <= 0) {
      //     this.$toasted.error('Giá trị end level phải lớn hơn 0!!', {theme: "toasted-primary", position: "top-right", duration: 4000});
      //     flat = false;
      //   }
      // }
      // if (this.entry_level_input >= this.end_of_course_level_input) {
      //   this.$toasted.error('Giá trị start level không thể lớn hơn hoặc bằng giá trị end level!!', {theme: "toasted-primary", position: "top-right", duration: 4000});
      //   flat = false;
      // }
      if (this.course_input === "" || this.course_input == null) {
        this.$toasted.error("Chưa chọn course!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.level_input === "" || this.level_input == null) {
        this.$toasted.error("Chưa chọn level!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditCourse(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        name: this.name_input,
        // entry_level: this.entry_level_input,
        // end_of_course_level: this.end_of_course_level_input,
        course_id: this.course_input,
        level_id: this.level_input,
      };
      this.is_call_api = true;
      if (type === 1) {
        data.offset = this.product_category_courses.length + 1;
        await ApiService.post(
          "prep-app/product-category/" + this.category_id + "/course",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllCategoryProductCourse();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogCourse = false;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put(
          "prep-app/product-category/" +
            this.category_id +
            "/course/" +
            this.category_course_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.getAllCategoryProductCourse();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogCourse = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    deleteCaCourse(category_course_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete(
            "prep-app/product-category/" +
              this.category_id +
              "/course/" +
              category_course_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllCategoryProductCourse();
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              vm.is_call_api = false;
            });
        }
      });
    },
    sortCaCourse() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              category_course_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/product-category/" + this.category_id + "/course/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllCategoryProductCourse();
                vm.$toasted.success("Sắp xếp thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
